import React from 'react';
import './App.css';
import Barra from './barra';

function Programacion() {
  return (
    <div>
      <Barra />
      <div>
        <section className="info mt-3 ">
          <div className="caja caja1">
            <h1>Programación</h1>
            <h3>Aprende conceptos sobre programación</h3>
          </div>
        </section>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col">
              <div >
                <video
                  controls
                  width="400"
                  height="400"
                  autoplay
                  loop
                  poster="../images/png/aprende y crea.png"
                >
                  <source src="https://corpstudios.s3.us-east-1.amazonaws.com/Bienvenida+al+curso+de+Matlab.mp4" type="video/mp4" />
                </video>
                <p class="card-text">Bienvenida al curso de matlab.</p>
              </div>
              <br></br>
            </div>
            <div class="col">
              <div >
                <video
                  controls
                  width="400"
                  height="400"
                  autoplay
                  loop
                  poster="../images/png/aprende y crea.png"
                >
                  <source src="https://corpstudios.s3.us-east-1.amazonaws.com/Introducci%C3%B3n+a+Matlab.mp4" type="video/mp4" />
                </video>
                <p class="card-text">Introducción al curso de matlab.</p>
              </div>
              <br></br>
            </div>
            <div class="col">
              <div >
                <video
                  controls
                  width="400"
                  height="400"
                  autoplay
                  loop
                  poster="../images/png/aprende y crea.png"
                >
                  <source src="https://corpstudios.s3.us-east-1.amazonaws.com/Matlab+casos+de+uso.mp4" type="video/mp4" />
                </video>
                <p class="card-text">Casos de uso de Matlab.</p>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <a href="/contenido" class="btn btn-dark">Volver</a>
      <section className="info mt-3">
        <footer className="footer">
          <div className="container d-flex flex-column align-items-center p-3 pb-0">
            <h1 className="footer-title text-center">
              Hagámoslo
              <br />
              <span>Real</span>
            </h1>
            <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            </div>

            <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
              <div className="footer-contact text-center">
                <h2>Contacto</h2>
                <p>johnatanmenasalcedo@gmail.com</p>
                <p>3146148505</p>
              </div>

              <div className="footer-bussines text-center">
                <h2>BIT</h2>
                <p>www.beaconsiottechnologies.com</p>
                <p>beaconsiottechnologies@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="copy d-flex justify-content-between mx-3 fs-6">
            <p>2025, &copy; Johnatan Mena</p>
            {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Programacion;