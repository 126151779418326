import React from 'react';
import './App.css';
import Barra from './barra';

function Contenido() {
  return (
    <div>
      <Barra />
      <div>
        <section className="info mt-3 ">
          <div className="caja caja1">
            <h1>Cursos</h1>
            <h3>Aprende y crea con Johnatan Mena</h3>
          </div>
        </section>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col">
              <div >
                <br></br>
                <img src="../images/jpg/iot.jpg" width={300} />
                <p class="card-text">Contenido educativo sobre el IOT.</p>
                <a href="/iot" class="btn btn-dark">Ver contenido</a>
              </div>
            </div>
            <br></br>
            <div class="col">
              <div >
                <br></br>
                <img src="../images/jpg/ami.jpg" width={300} />
                <p class="card-text">Contenido educativo sobre ingeniería biomédica.</p>
                <a href="biomedica" class="btn btn-dark">Ver contenido</a>
              </div>
            </div>
            <br></br>
            <div class="col">
              <div >
                <br></br>
                <img src="../images/png/progra.png" width={300} />
                <p class="card-text">Contenido educativo sobre programación.</p>
                <a href="programacion" class="btn btn-dark">Ver contenido</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="info mt-3">
        <footer className="footer">
          <div className="container d-flex flex-column align-items-center p-3 pb-0">
            <h1 className="footer-title text-center">
              Hagámoslo
              <br />
              <span>Real</span>
            </h1>
            <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            </div>

            <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
              <div className="footer-contact text-center">
                <h2>Contacto</h2>
                <p>johnatanmenasalcedo@gmail.com</p>
                <p>3146148505</p>
              </div>

              <div className="footer-bussines text-center">
                <h2>BIT</h2>
                <p>www.beaconsiottechnologies.com</p>
                <p>beaconsiottechnologies@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="copy d-flex justify-content-between mx-3 fs-6">
            <p>2025, &copy; Johnatan Mena</p>
            {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Contenido;