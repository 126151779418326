import React from 'react';
import './App.css';
import Barra from './barra';

function Bio() {
  return (
    <div>
      <Barra />
      <div>
        <section className=" ">
          <div className="info mt-3">
            <h1>Conociendo a Johnatan Mena</h1>
          </div>
        </section>
        <div className="mena container-lg container-md mt-lg-5 py-lg-4 mt-md-1 py-md-4 mt-sm-1 py-sm-3  mt-2 py-3 ">
          <p className="bio-text_mena">
            Johnatan Alexander Mena Salcedo nació el 12 de noviembre de 1991 en Medellín,
            Colombia, y pasó su infancia en el municipio de Chigorodó, Antioquia.
            Desde temprana edad, mostró un interés notable por la tecnología, experimentando
            con las computadoras de la época. Su pasión por la música lo llevó a explorar el
            mundo de la informática, desarrollando habilidades avanzadas en software de edición,
            grabación y producción de audio.
            A los 15 años, comenzó a generar ingresos ofreciendo servicios tecnológicos
            como formateo de computadoras, grabación de canciones caseras, diseño fotográfico y
            creación de CD musicales personalizados. Estas experiencias tempranas marcaron el inicio
            de su recorrido en el mundo de la tecnología.
          </p>
          <section>
          <div id="carouselExampleIndicators" className="carousel slide">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="../images/jpg/old2.jpg" className="d-block w-100" alt="clientes" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/jpg/itm.jpg" className="d-block w-100" alt="gateway" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/jpg/studio.jpg" className="d-block w-100" alt="bit" height={400} />
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          </section>
          <p></p>
          <p></p>
          <p className="bio-text_mena">
            Tras trabajar en instituciones de salud en la región de Urabá,
            Johnatan se trasladó a Medellín en 2015 para estudiar Ingeniería Biomédica.
            Fue en esta etapa donde retomó su pasión por la informática, comenzando a explorar
            la programación y los sistemas electrónicos. Durante su tiempo en la universidad,
            conoció a sus futuros socios, Mateo Osorio y Daniel Arteaga, con quienes desarrolló
            proyectos de machine learning utilizando Python y MATLAB. Además, incursionó en el
            desarrollo web, creando su primer videojuego, Turkano's Game, en JavaScript.
            En 2021, Johnatan obtuvo el título de Ingeniero Biomédico y
            comenzó su carrera profesional como metrólogo en la empresa Análisis Metrológico
            Industrial. A pesar de tener un rol operativo, desarrolló de forma independiente una
            aplicación web para el control de equipos patrón, lo que le abrió nuevas oportunidades
            laborales.
          </p>
          <section>
          <div id="carouselExampleIndicators1" className="carousel slide">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="../images/png/bit.png" className="d-block w-100" alt="clientes" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/jpg/costa.jpg" className="d-block w-100" alt="gateway" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/jpg/imt2.jpg" className="d-block w-100" alt="bit" height={400} />
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          </section>
          <p></p>
          <p></p>
          <p className="bio-text_mena">
            Gracias a su experiencia en el sector salud, identificó necesidades
            relacionadas con la gestión de equipos biomédicos, lo que lo inspiró a importar
            balizas BLE (beacons) y desarrollar un sistema de localización en tiempo real.
            Así nació Beacons IoT Technologies, una empresa innovadora que combina tecnología y
            eficiencia.

            Más adelante, con el apoyo de su colega Mateo Osorio, Johnatan ingresó a Cala-Analytics
            como consultor analítico, donde perfeccionó sus habilidades en programación y adquirió
            una sólida experiencia en tecnologías de la información. Su trayectoria
            refleja una pasión constante por la innovación tecnológica y una capacidad destacada
            para transformar ideas en soluciones prácticas que impactan positivamente diversos
            sectores.
          </p>
          <section>
          <div id="carouselExampleIndicators2" className="carousel slide">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="../images/png/johnatan.png" className="d-block w-100" alt="clientes" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/png/familia.png" className="d-block w-100" alt="gateway" height={400}  />
              </div>
              <div className="carousel-item">
                <img src="../images/jpg/relax.jpg" className="d-block w-100" alt="bit" height={400} />
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          </section>
        </div>
      </div>
      <section className="info mt-3">
        <footer className="footer">
          <div className="container d-flex flex-column align-items-center p-3 pb-0">
            <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            </div>
            <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
              <div className="footer-contact text-center">
                <h2>Contacto</h2>
                <p>johnatanmenasalcedo@gmail.com</p>
                <p>3146148505</p>
              </div>

              <div className="footer-bussines text-center">
                <h2>BIT</h2>
                <p>www.beaconsiottechnologies.com</p>
                <p>beaconsiottechnologies@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="copy d-flex justify-content-between mx-3 fs-6">
            <p>2025, &copy; Johnatan Mena</p>
            {/* <p className="otto text-uppercase">&copy; Johnatan Mena</p> */}
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Bio;