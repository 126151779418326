import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './App.css';

function Barra() {
  return (
    <div>
        <Navbar className='celeste' expand="lg" >
        <Navbar.Brand href="#"><img src="../images/png/jm.png" alt="Logo" width="60"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="negrita">
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/bio">Biografía</Nav.Link>
            <Nav.Link href="/experiencia">Experiencia</Nav.Link>
            <Nav.Link href="/contenido">Contenido</Nav.Link>
            <Nav.Link href="/curriculum">Ver curriculum</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Barra;